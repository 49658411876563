import * as React from 'react';

import { compose } from 'recompose';

import Retracked from 'js/app/retracked';
import { useLocation } from 'js/lib/useRouter';

import PageFooter from 'bundles/page-footer/components/PageFooter';
import PageHeader from 'bundles/page-header/components/PageHeader';
import { CourseraMetatags } from 'bundles/seo';
import withMarketingConsent from 'bundles/user-consent/components/withMarketingConsent';

import 'css!bundles/accomplishments/styl/index';

type Props = {
  children: JSX.Element;
};

const AccomplishmentsApp = ({ children }: Props) => {
  const { pathname } = useLocation();

  const isCertificatePage = pathname.includes('certificate');
  return (
    <div className="rc-AccomplishmentsApp">
      <CourseraMetatags metaNameAndProperties={{ disableCrawlerIndexing: true }} />
      {!isCertificatePage && <PageHeader showShoppingCart={true} showGDPRBanner />}
      <div style={{ minHeight: '60vh' }}>{children}</div>
      {!isCertificatePage && <PageFooter />}
    </div>
  );
};

export default compose<Props, {}>(
  Retracked.createTrackedContainer(() => {
    return {
      namespace: {
        app: 'accomplishments_v2',
        page: 'accomplishments_pages',
      },
    };
  }),
  withMarketingConsent
)(AccomplishmentsApp);
